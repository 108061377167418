







import { View } from 'client-website-ts-library/plugins';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class PropertyReportPreview extends Mixins(View) {
  mounted() {
    let scr: HTMLScriptElement | null = document.getElementById('scrCoreLogicIngegration') as HTMLScriptElement | null;
    if (scr) scr.remove();

    setTimeout(() => {
      scr = document.createElement('script');
      scr.src = 'https://dpr.leadplus.com.au/main.js';
      scr.async = true;
      scr.defer = true;
      scr.id = 'scrCoreLogicIngegration';

      scr.onload = () => {
        console.log('Core Logic Integration Loaded');
      };

      document.body.append(scr);
    }, 500);
  }
}
